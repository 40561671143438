@import "utilities/reset.css";
/* meyer reset */
@import "utilities/fonts.css";
@import "./components/navbar.css";
@import "./components/hero.css";
@import "./components/effects.css";
@import "./components/profiles-gallery.css";
@import "./components/filter-frame.css";
@import "./components/info.css";
@import "./components/footer.css";
@import "components/help.css";
@import "./components/about.css";
@import "./components/lightbox.css";

/*@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");*/

:root {
    --black: #000;
    --darkest: #111;
    --dark: #252525;
    --darkgrey: #333;
    --grey: #777;
    --lightgrey: #999;
    --green: #44d62c;
    --white: #fff;
    --light: 300;
    --regular: 400;
    --semibold: 600;
    --bold: 700;

    --swatchsize: 10px;

    --chroma: linear-gradient(
            90deg,
            #ff0000 4.17%,
            #ffff00 27.6%,
            #ffff00 34.79%,
            #00ffff 65.52%,
            #3300ff 80.57%,
            #ff00ff 95.63%
    );
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: var(--dark);
    overflow: auto;
}

.divider-gap {
    height: 15px;
}
