.hero {
    background-color: var(--black);
    background-image: url(../assets/resul-kaya-xxL1FavYOh0-unsplash-cut.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    --carouselDuration: 0.5s;
    --slide-in: cubic-bezier(0.25, 0.91, 0.565, 1);

    font-family: "Titillium Web", sans-serif;
}

@media (min-width: 960px) {
    .hero {
        height: 555px;
    }

    .hero-title-frame {
        height: 165px;
    }

    .hero-heading {
        font-size: 52px;
        padding: 10px;
    }

    .hero-subheading {
        font-size: 28px;
    }
}

@media (max-width: 960px) {
    .hero {
        background-image: url(../assets/resul-kaya-xxL1FavYOh0-unsplash-cut.jpg);
    }

    .hero-heading {
        font-size: max(20px, calc(100vw * 0.055));
    }

    .hero-subheading {
        font-size: max(18px, calc(100vw * 0.03));
    }

    .hero-title-frame {
        height: calc(100vw * (165 / 960));
    }
}

.hero-title-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-heading {
    /* featured profile */
    display: table;
    font-weight: var(--semibold);
    letter-spacing: -0.022em;
    color: var(--white);
}

.hero-subheading {
    /* Profile of the Month */
    display: table;
    font-weight: var(--regular);
    text-align: center;
    text-transform: uppercase;
}

.hero-banner-frame {
    position: relative;
    width: 100%;
    display: grid;
    justify-content: center;
}

/***** Carousel starts *****/

.profile-card {
    position: absolute;
    width: 960px;
    height: 330px;
    background-color: black;
}

.card-media {
    position: absolute;
    z-index: 2;
}

.card-media.middle,
.card-media.left,
.card-media.back {
    right: auto;
    left: 0;
}

.card-media.right {
    left: auto;
    right: 0;
}

.profile-card.left {
    left: 0;
    transform: scale(0.75);
}

.profile-card.right {
    right: 0;
    transform: scale(0.75);
}

/* side properties only */
.profile-card.left,
.profile-card.right {
    filter: brightness(35%) blur(3px);
    z-index: 1;
}

.profile-card.left:hover,
.profile-card.right:hover {
    filter: brightness(100%) blur(0px);
    cursor: pointer;
}

.profile-card.middle,
.profile-card.back {
    left: calc(50% - (960px / 2));
}

.profile-card.middle {
    z-index: 6;
}

.profile-card.back {
    transform: scale(0.5);
    filter: brightness(35%) blur(3px);
    z-index: 0;
}

@keyframes img-right-to-left {
    0% {
        left: auto;
        right: 0;
    }
    100% {
        right: calc((960px - 586px));
    }
}

@keyframes img-left-to-right {
    0% {
        left: auto;
        right: calc((960px - 586px));
    }
    100% {
        left: auto;
        right: 0;
    }
}

@keyframes front-to-side {
    0% {
        z-index: 6;
        transform: scale(1);
        filter: brightness(100%) blur(0px);
    }
    100% {
        z-index: 2;
        transform: scale(0.75);
        filter: brightness(35%) blur(3px);
    }
}

@keyframes side-to-front {
    0% {
        z-index: 5;
        transform: scale(0.75);
        filter: brightness(100%) blur(0px);
    }
    100% {
        z-index: 6;
        transform: scale(1);
        filter: brightness(100%) blur(0px);
    }
}

@keyframes side-to-back {
    0% {
        z-index: 2;
        transform: scale(0.75);
        filter: brightness(35%) blur(3px);
    }
    100% {
        z-index: 0;
        transform: scale(0.5);
        filter: brightness(35%) blur(3px);
    }
}

@keyframes back-to-side {
    0% {
        z-index: 0;
        transform: scale(0.5);
        filter: brightness(35%) blur(3px);
    }
    100% {
        z-index: 2;
        transform: scale(0.75);
        filter: brightness(35%) blur(3px);
    }
}

@keyframes left-to-mid {
    0% {
        left: 0;
        transform: scale(0.75);
    }
    100% {
        left: calc(50% - (960px / 2));
        transform: scale(1);
    }
}

@keyframes mid-to-left {
    0% {
        left: calc(50% - (960px / 2));
        z-index: 6;
        transform: scale(1);
    }
    100% {
        left: 0;
        transform: scale(0.75);
    }
}

@keyframes right-to-mid {
    0% {
        right: 0;
    }
    100% {
        right: calc(50% - (960px / 2));
    }
}

@keyframes mid-to-right {
    0% {
        left: auto;
        right: calc(50% - (960px / 2));
        transform: scale(0.5);
    }
    100% {
        left: auto;
        right: 0;
        transform: scale(0.75);
    }
}

/*.profile-card video,*/
.videojs-hero-banner {
    cursor: pointer;
    background-color: black;
}

.profile-card img {
    object-fit: contain;
}

@media (max-width: 960px) {
    .profile-card.left,
    .profile-card.back,
    .profile-card.right {
        display: none;
    }

    .profile-card.middle {
        box-sizing: border-box;
        position: static;
        width: auto;
        height: auto;
    }

    .card-media.middle {
        width: 100%;
        position: static;
    }

    .profile-card img,
    div.videojs-hero-banner {
        width: 98vw;
        height: auto;
    }

}

@media (min-width: 960px) {
    .profile-card {
        position: absolute;
        display: flex;
        width: 960px;
        height: 330px;
    }

    /*.profile-card video,*/
    .profile-card img,
    div.videojs-hero-banner {
        width: 586px !important;
        height: 330px !important;
    }
}
