
section.relative-parent {
    position: relative;
}

.about.title-frame {
    position: sticky;
    top: 10vw;
    grid-row: 1;
    grid-column: 1;
    margin: 0 auto auto auto;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transform: translateZ(-2px) scale(calc(1 + 2 / 10));
}


.about.title-frame h1 {
    font-family: "Titillium Web", sans-serif;
    font-size: 48px;
    line-height: 80%;
    margin-right: 15px;
    color: var(--lightgrey);
}

.about.hero-frame {
    display: grid;
    position: relative;
    box-sizing: border-box;
    perspective: 10px;
}

.about-video {
    grid-row: 1;
    grid-column: 1;
    box-sizing: border-box;
}

.about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white);
    font-family: "Titillium Web", sans-serif;
    line-height: 180%;
    padding: 50px;
    position: relative;
    background-image: url("../assets/StockSnap_1STVFMTBJY_edited.jpg");
    background-attachment: fixed;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}


.about-content-header {
    font-size: 32px;
    font-weight: var(--regular);
    margin: 30px auto 50px auto;
    text-align: center;
}

.about-content div p {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    color: var(--lightgrey);
    font-size: 18px;
    margin: 0 auto 20px auto;
}

.about-content a {
    color: var(--green);
    text-decoration: none;
}

.about-content a:hover {
    color: var(--white);
    text-decoration: underline green;
    transition: 0.3s;
}

.about-content-foreground {
    background-color: rgba(0, 0, 0, 85%);
    max-width: 650px;
    padding: 3vw;
    margin-bottom: 50px;
}

