.navbar {
    display: flex;
    position: sticky;
    top: 0;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    height: 60px;

    font-family: "Titillium Web", sans-serif;
    color: var(--white);
    background-color: var(--black);
    z-index: 6;
}

.nav-contents {
    display: flex;
    width: 700px;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
}

.nav-element {
    cursor: pointer;
    color: var(--white);
    transition: 0.4s;
}

.nav-element:hover {
    color: var(--green);
}

.nav-contents button,
.nav-contents a {
    font-family: "Titillium Web", sans-serif;
    font-size: 16px;
    text-decoration: none;
    color: var(--white);
    background-color: var(--black);
    border: 0;
    cursor: pointer;
}

.nav-hamburger {
    color: var(--lightgrey);
    padding: 0 5px;
    font-size: 28px;
    cursor: pointer;
}

.nav-hamburger:active {
    color: var(--white);
}

@media (min-width: 450px) {
    .nav-hamburger {
        display: none;
    }
}

@media (max-width: 450px) {
    .nav-element {
        display: none;
    }

    .login-username {
        display: none;
    }
}

.nav-dropdown-button {
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

div.nav-dropdown-menu {
    display: flex;
    flex-direction: column;
    width: min-content;
    position: absolute;
    top: 60px;
    left: 5px;
    border: 1px solid var(--lightgrey);
    box-sizing: border-box;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    z-index: 70;
}

.nav-dropdown-menu.nav-link {
    padding: 15px 50px;
    font-size: 32px;
}

#nav-dropdown-toggle:checked ~ .nav-dropdown-menu {
    opacity: 1;
    transition: 0.3s;
    pointer-events: auto;
}

.logo-chroma {
    /* CHROMA */
    font-family: "Titillium Web", sans-serif;
    font-weight: var(--regular);
    letter-spacing: -0.02em;
}

.logo-gallery {
    /* GALLERY */
    font-family: "Titillium Web", sans-serif;
    font-weight: var(--bold);

    color: var(--white);
}

.logo-small {
    font-size: 24px;
    line-height: 80%;
}


.logo-large {
    font-size: 60px;
    line-height: 80%;
}

@media (max-width: 768px) {
    .logo-large {
        font-size: 36px;
    }
}


.login {
    display: flex;
    align-items: center;
    gap: 16px;

    color: var(--lightgrey);
}

.login-icon {
    color: var(--lightgrey);
    font-size: 32px;
}
