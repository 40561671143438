:root {
    --fa-angle-left: "\f104";
    --fa-angle-right: "\f105";
    --fa-angle-up: "\f106";
    --fa-angle-down: "\f107";
    --fa-plus: "\2b";
    --fa-magnifying-glass: "\f002";
    --fa-filter: "\f0b0";
    --fa-keyboard: "\f11c";
    --fa-hurricane: "\f751";
}

.float-shadow-text {
    text-shadow: 0 2px 15px rgba(0, 0, 0, 0.75);
}

.float-shadow {
    filter: drop-shadow(0px 2px 15px rgba(0, 0, 0, 0.75));
}

.hidden-checkbox {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
}

/* Chroma */
.chroma-gradient {
    background: linear-gradient(
            90deg,
            #ff0000 4.17%,
            #ffff00 27.6%,
            #ffff00 34.79%,
            #00ffff 65.52%,
            #3300ff 80.57%,
            #ff00ff 95.63%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /*noinspection CssInvalidPropertyValue*/
    background-clip: text;
    /*background-size: 400% 400%; */
    animation: gradient 1s ease;
}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.flex-columns {
    display: flex;
    flex-direction: column;
}

.flex-rows {
    display: flex;
    flex-direction: row;
}

.box-sizing {
    box-sizing: border-box;
}

.red-border {
    border: 1px solid red;
}

.green-border {
    border: 1px solid green;
}

.blue-border {
    border: 1px solid blue;
}

.yellow-border {
    border: 1px solid yellow;
}

.fuchsia-border {
    border: 1px solid fuchsia;
}

.green-background {
    background-color: green;
}

.bg-red {
    background-color: red;
}

.bg-yellow {
    background-color: yellow;
}

.bg-green {
    background-color: green;
}

.bg-cyan {
    background-color: cyan;
}

.bg-purple {
    background-color: purple;
}

.bg-fuchsia {
    background-color: fuchsia;
}

.animate-entrance {
    animation-duration: 0.5s;
    animation-name: animate-fade;
    animation-delay: 0.1s;
    animation-fill-mode: backwards;
}

.delay-1 {
    animation-delay: 0.3s;
}

.delay-2 {
    animation-delay: 0.4s;
}

.delay-3 {
    animation-delay: 0.5s;
}

.delay-4 {
    animation-delay: 0.6s;
}

.delay-5 {
    animation-delay: 0.7s;
}

.delay-6 {
    animation-delay: 0.8s;
}

.delay-7 {
    animation-delay: 0.9s;
}

.delay-8 {
    animation-delay: 1.0s;
}

.delay-9 {
    animation-delay: 1.1s;
}

.delay-0 {
    animation-delay: 1.2s;
}

@keyframes animate-fade {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.animate-entrance.pop {
    animation-duration: 0.4s;
    animation-name: animate-pop;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
}

@keyframes animate-pop {
    0% {
        opacity: 0;
        transform: scale(0.5, 0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

@media screen and (prefers-reduced-motion: reduce) {
    .animate-entrance {
        animation: none !important;
    }
}
