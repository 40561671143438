.profiles-search {
    scroll-margin-top: 60px;
    background-position: center top;
    background-repeat: no-repeat;
}

.image-background {
    background-color: var(--black);
    background-image: url(../assets/pexels-maik-poblocki-3812048-cut.jpg);
    background-repeat: no-repeat;
}

h2.profiles-title {
    text-align: center;
    padding-top: 33px;
    padding-bottom: 15px;
    line-height: 55px;
    color: var(--white);
    font-family: "Titillium Web", sans-serif;
    font-size: 36px;
    font-weight: var(--regular);
    letter-spacing: -0.025em;
}

section.search-bars {
    position: sticky;
    z-index: 5;
    top: 60px;
    padding-bottom: 10px;
    /* background-color: var(--black);
    background-image: url(pexels-maik-poblocki-3812048-cut.jpg); */
    background-position: center -103px;
    background-repeat: no-repeat;
}

div.search-bars {
    margin: auto;
    max-width: 960px;
    /* background-color: red; */
    color: var(--green);
    font-family: "Titillium Web", sans-serif;
    font-size: 14px;
    padding-top: 5px;
    box-sizing: border-box;
    /* border: 1px solid yellow; */
}

form.search-bars {
    /* width: 960px; */
    /* margin: auto; */
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    gap: 4px;
    box-sizing: border-box;
    /* border: 1px solid cyan; */
}

@media (max-width: 960px) {
    form.search-bars {
        padding: 0 2vw;
    }

    .filter-label {
        padding: 0 2vw;
    }
}

form.search-bars input[type="text"], button, label.dropdown-button {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    /* height: 30px; */
    border: none;
    /*border-radius: 6px;*/
    background-color: var(--dark);
    color: var(--lightgrey);
    box-sizing: border-box;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    display: block;
    line-height: 30px;
}

input[type="text"]::placeholder {
    color: var(--lightgrey);
    opacity: 1;
}

.flying-checkbox {
    position: absolute;
    left: -50px;
    opacity: 1;
    z-index: 5;
}

.dropdown-menu-label {
    display: block;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.dropdown-menu-item:checked ~ label.dropdown-menu-label::before {
    content: "\f00c";
    color: var(--green);
    margin-right: 8px;
    opacity: 1;
    transition: 0.1s ease-out;
}

label.dropdown-menu-label::before {
    display: inline-block;
    font-family: "Font Awesome 6 Free", serif;
    font-weight: 900;
    line-height: initial;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: "";
    opacity: 0;
    transition: 0.1s ease-out;
}

div.input.frame {
    flex-basis: 48%;
    display: flex;
    gap: 5px;
    flex-grow: 1;
}

div.input {
    min-height: 30px;
}

div.input.search {
    flex-grow: 1;
}

.icon-anchor::before, .icon-anchor::after {
    position: absolute;
    top: 0;
    line-height: 30px !important;
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font: var(--fa-font-solid);
    font-size: 30px;
    pointer-events: none;
}

div.input.tab {
    flex-grow: 1;
    flex-basis: 30%;
    min-width: 110px;
}

div.input.search {
    min-width: 250px;
}

.icon-anchor::before {
    left: 10px;
    font-size: 18px;
    color: var(--lightgrey);
}

.icon-anchor::after {
    right: 10px;
    font-size: 14px;
    color: var(--green);
    transition: 0.1s;
}

.icon-anchor.search::before {
    content: var(--fa-magnifying-glass);
}

.icon-anchor.search::after {
    content: var(--fa-angle-left);
}

.icon-anchor.filter::before {
    content: var(--fa-filter);
}

.icon-anchor.filter::after {
    content: var(--fa-plus);
}

.icon-anchor.devices::before {
    content: var(--fa-keyboard);
}

.icon-anchor.devices::after {
    content: var(--fa-plus);
}

.icon-anchor.effects::before {
    content: var(--fa-hurricane);
}

.icon-anchor.effects::after {
    transform: rotate(90deg);
    content: var(--fa-plus);
}

div.input {
    position: relative;
    height: 30px;
}

/*-------------- dropdowns ----------- */
label.dropdown-button {
    cursor: pointer;
}

div.dropdown-menu {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    /*height: min-content;*/
    max-height: calc(100vh / 2);
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    overflow-x: auto;
}

div.dropdown-menu.devices {
    width: calc(200% + 5px);
}

div.dropdown-menu ul {
    margin-top: 5px;
    padding: 5px 10px 10px 10px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 150%;
    color: var(--lightgrey);
    background-color: var(--dark);
}

.dropdown-menu li {
    margin: 5px 0;
    padding: 0 5px;
    transition: 0.3s;
    cursor: pointer;
}

.dropdown-menu li:hover,
.dropdown-menu-item:checked ~ label.dropdown-menu-label:hover::before {
    background-color: var(--green);
    color: var(--black);
    transition: 0.3s;
}

#filter-by-toggle:checked ~ .dropdown-menu,
#filter-by-toggle:hover ~ .dropdown-menu,
#filter-effects-toggle:checked ~ .dropdown-menu,
#filter-effects-toggle:hover ~ .dropdown-menu,
input[name="devices"]:focus ~ .dropdown-menu,
input[name="devices"]:hover ~ .dropdown-menu,
.dropdown-menu.filter:hover,
.dropdown-menu.effects:hover,
.dropdown-menu.devices:hover,
.dropdown-menu.search:hover {
    opacity: 1;
    transition: 0.3s;
    pointer-events: auto;
}

input[name="search"].dropdown-button:focus ~ .icon-anchor.search::after,
input[name="search"].dropdown-button:hover ~ .icon-anchor.search::after,
div.dropdown-menu.search:hover ~ .icon-anchor.search::after {
    content: var(--fa-angle-left);
    transform: rotate(180deg);
    transition: 0.3s;
}

#filter-by-toggle:checked ~ .icon-anchor.filter::after,
#filter-by-toggle:hover ~ .icon-anchor.filter::after,
div.dropdown-menu.filter:hover ~ .icon-anchor.filter::after {
    content: var(--fa-angle-up);
    transform: rotate(-180deg);
    transition: 0.3s;
}

input[name="devices"]:focus ~ .icon-anchor.devices::after,
input[name="devices"]:hover ~ .icon-anchor.devices::after,
div.dropdown-menu.devices:hover ~ .icon-anchor.devices::after {
    content: var(--fa-angle-up);
    transform: rotate(-180deg);
    transition: 0.3s;
}

#filter-effects-toggle:checked ~ .icon-anchor.effects::after,
#filter-effects-toggle:hover ~ .icon-anchor.effects::after,
div.dropdown-menu.effects:hover ~ .icon-anchor.effects::after {
    content: var(--fa-angle-up);
    transform: rotate(-180deg);
    transition: 0.3s;
}


input:focus {
    outline: solid 1px var(--white);
    box-shadow: 0 0 8px #ffffff;
}


.profiles-gallery {
    display: flex;
    width: 100%;
    padding-top: 20px;
    background-color: var(--black);
    justify-content: center;
}

.outer-frame {
    display: flex;
    justify-content: center;
}

.profiles-frame {
    display: grid;
    grid-template-columns: repeat(auto-fill, 380px);
    gap: 12px;
    margin: 0 auto;
    justify-items: center;
}

@media (min-width: 772px) {
    .profiles-frame {
        width: 772px;
    }
}

@media (min-width: 1164px) {
    .profiles-frame {
        width: 1164px;
    }
}

@media (min-width: 1567px) {
    .profiles-frame {
        width: 1567px;
    }
}

.profile {
    display: flex;
    flex-direction: column;

    border: 1px solid var(--dark);
    background-color: var(--black);
    box-sizing: border-box;
}

@keyframes zoom-profile {
    0% {
        transform: scale(1, 1);
        /* border: 1px solid var(--dark); */
    }
    100% {
        transform: scale(1.1, 1.1);
        border: 1px solid var(--lightgrey);
        filter: drop-shadow(0px 2px 15px rgba(0, 0, 0, 1));
    }
}

@media (min-width: 380px) {
    .video-box {
        width: 380px;
        height: 214px;
        z-index: 1;
    }
}

@media (min-width: 450px) {
    .profile:hover {
        z-index: 6;
        animation: zoom-profile 0.3s 1 cubic-bezier(0.26, 0.53, 0.74, 1.48) 0.1s forwards;
    }
}

@media (max-width: 380px) {
    .video-box {
        width: calc(99vw - 2px);
        height: calc((99vw - 2px) / 16 * 9);
        margin: 0 auto;
        z-index: 1;
    }
}

.video-box img {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.video-box video {
    width: 100%;
    height: 100%;
    z-index: 1;
}

div.grid-span {
    grid-column: 1/-1;
}

div.loading {
    font-family: "Titillium Web", sans-serif;
    font-size: 16px;
    color: var(--white);
    margin: 5vh;
    text-align: center;
    line-height: 150%;
}

.gallery-footer {
    font-family: "Titillium Web", sans-serif;
    font-size: 16px;
    padding: 5vh;
}


.gallery-footer a {
    /*font-family: "Titillium Web", sans-serif;*/
    /*font-size: 16px;*/
    text-decoration: none;
    color: var(--white);
    background-color: var(--black);
    cursor: pointer;
    border: 1px solid var(--green);
    border-radius: 6px;
    text-align: center;
    line-height: 24px;
    box-sizing: border-box;
}

.gallery-footer a:hover {
    color: var(--green);
    background-color: var(--dark);
}

.footer-arrow {
    font-size: 20px;
    line-height: 24px;
    margin: 0 20px;
}