.infopanel {
    display: flex;
    /*flex-direction: column;*/
    /*justify-content: space-between;*/
    color: var(--white);
    background-color: var(--black);
    z-index: 2;
    font-family: "Titillium Web", sans-serif;
}

.infopanel.small {
    flex-grow: 1;
    box-sizing: border-box;
    /*gap: 15px;*/
}

@media (min-width: 380px) {
    .infopanel.small {
        width: 380px;
        padding: 25px 0;
    }
}

@media (max-width: 380px) {
    .infopanel.small {
        max-width: 90vw;
        margin: 0 auto;
        padding: 5vw 0;
        box-sizing: border-box;
    }
}

.infopanel.large {
    box-sizing: border-box;
    padding: 25px 0;
    /*margin: 25px 0;*/
    /*gap: 15px;*/
}

.info-frame {
    overflow: hidden; /* somehow you need to add this to make flex-grow not exceed 100% */
    /*overflow-y: visible; !* somehow you need to add this to make flex-grow not exceed 100% *!*/
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    /*padding: 25px 0;*/
}

.info-page-button {
    min-width: 25px;
    max-width: 25px;
    text-align: center;
    margin: auto;
    cursor: pointer;
}

.info-page-button:hover {
    color: var(--green);
    transition: 0.2s
}

/* Infopanel.large will float (in Desktop mode > 960px) */
@media (min-width: 960px) {
    .infopanel.large {
        position: absolute;
        left: auto;
        right: 0;
        z-index: 1;
        width: 374px;
        height: 330px;
    }
}

@media (max-width: 960px) {
    .infopanel.large {
        position: static;
        width: 100%;
        height: max-content;
        align-content: center;
    }
}

.info-titlebox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    /*font-family: "Titillium Web", sans-serif;*/
}

.info-title,
.info-title a:link,
.info-title a:visited {
    /*font-size: 18px;*/
    /*line-height: 100%;*/
    /*letter-spacing: -0.025em;*/
    /*color: var(--white);*/
    color: var(--lightgrey);
    font-family: roboto, sans-serif;
    font-style: italic;
    font-size: 14px;
    line-height: 14px;
    text-decoration: none;
}

.info-title a:hover {
    color: var(--green);
    transition: 0.3s;
}

.info-author {
    margin-bottom: 5px;
}

.info-author-by {
    margin-right: 5px;
    font-size: 14px;
    color: var(--grey);
}

.info-author-name {
    color: var(--green);
}

.colour-palette-section {
    display: flex;
    max-width: 100%;
    /*justify-content: center;*/
    align-content: baseline;
    gap: 2px;
    line-height: var(--swatchsize);
    margin-bottom: 8px;
}

.profile-name {
    margin-right: 5px;
    font-size: 20px;
    letter-spacing: -0.025em;
    color: var(--white);
}

div.colour-palette-section div {
    height: var(--swatchsize);
    width: var(--swatchsize);
}

div.all-tags-frame {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

div.tags-frame {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
}

div.tags-frame.devices {
    flex-shrink: 1;
    min-height: 0;
    overflow-y: auto;
}

div.tags-frame div {
    width: max-content;
    background-color: var(--darkgrey);
    color: var(--lightgrey);
    font-weight: var(--semibold);
    line-height: 100%;
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 14px;
}

div.like-and-download {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

div.like-and-download span {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
}

div.like-and-download span span,
a.icon-button:link,
a.icon-button:visited,
span.icon-button {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: var(--regular);
    color: var(--white);
    text-decoration: none;

}

.icon-button-icon {
    font-size: 24px;
    line-height: 100%;
    color: var(--green);
    transition: 0.3s;
}

a.icon-button:hover,
span.icon-button:hover {
    transform: scale(1.1, 1.1);
    transition: 0.1s;
    cursor: pointer;
}

